@import './_colors';

.ogc-container {
  width: 230px;
  height: 241px;
  border-radius: 10px;
  border: solid 1px $rps-ogc-off-white;
  background-color: $rps-white;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.ogc-header {
  display: flex;
  justify-content: center;
}

.ogc-card-title,
.ogc-opponent-name,
.ogc-vs {
  font-size: 25px;
  font-weight: bold;
}

.ogc-vs {
  @extend .ogc-card-title;
  color: $rps-red;
  margin-right: 10px;
}

.ogc-stakes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ogc-buyin,
.ogc-round-buyin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ogc-stake-header {
  font-size: 12px;
  color: $rps-ogc-grey;
}

.ogc-stake-amount {
  font-size: 18px;
}

.ogc-stake-currency {
  font-size: 10px;
}

.ogc-divider {
  width: 1px;
  height: 14px;
  stroke: $rps-ogc-divider-grey;
}

.ogc-join {
  width: 190px;
  height: 36px;
  border-radius: 40px;
  background-color: $rps-red;
  border-color: $rps-red;

  font-size: 18px;
  font-weight: bold;
  color: $rps-white;
}
