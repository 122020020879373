@import './_colors';
@import './dimensions';

.navbar {
  background-color: $rps-white;
  height: $navbar-height;
}

.navbar-button {
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $rps-footer-grey;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: $rps-red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-user {
  font-size: 15px;
  color: $rps-white;
}
