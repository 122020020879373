@import './_colors';

$loginButton-height: 44px;
$homePage-container-max-width: 70%;
$background-image-size: contain;
$background-image-width: 70%;
$background-image-max-width: 400px;

.homePage {
  background-color: $rps-dark-blue;
  height: 100%;
  position: absolute;
  width: 100%;
}

.homePage-container {
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-top: 25%;
  max-width: $homePage-container-max-width;
}

.homePage-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 70%;
}

.homePage-loginButton {
  margin-top: $loginButton-height;
  font-size: 20px;
}

.homePage-image {
  background-image: url(images/homepage_image.png);
  background-repeat: no-repeat;
  background-size: $background-image-size;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  width: $background-image-width;
  max-width: $background-image-max-width;
}
