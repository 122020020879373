.confirm-button {
  width: 100px;
  height: 36px;
  border-radius: 40px;
  background-color: $rps-red;
  border-color: $rps-red;
  margin: 5px;
  font-size: 18px;
  font-weight: bold;
  color: $rps-white;
}
