@import './_colors';

.cog-container {
  width: 350px;
  height: 400px;
  border-radius: 10px;
}

.cog-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cog-input-group {
  display: flex;
  align-items: center;
}

.cog-input {
  width: 50%;
  margin-right: 10px;
  text-align: right;
}

.cog-button {
  width: 252px;
  height: 50px;
  border-radius: 40px;
  background-color: $rps-red;
  border-color: $rps-red;
}

.cog-error-message {
  max-width: 225px;
  min-height: 40px;
}
