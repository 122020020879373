@import '../_colors';
@import '../_fonts';

.welcome-title {
  font-family: $rps-font-family;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
}

.welcome-text {
  font-family: $rps-font-family;
}
.welcome-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcome-container {
  padding: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.welcome-button {
  font-family: $rps-font-family;
  background-color: $rps-wallet-orange;
  border-color: $rps-wallet-orange;
  color: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 0 auto;
}

.welcome-cancel-button {
  font-family: $rps-font-family;
  background-color: $rps-wallet-muted;
  border-color: $rps-wallet-muted;
  color: $rps-wallet-grey;
  align-self: flex-start;
  margin: auto 0 0 0;
}
.welcome-spacer {
  margin: auto 0 0 0;
}
.welcome-icon {
  height: 50px;
  width: 50px;
  margin: 150px auto 0 auto;
}
