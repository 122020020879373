$rps-light-grey: rgba(255, 255, 255, 0.3);
$rps-dark-blue: #151b44;
$rps-light-blue: #4285f4;
$rps-red: #e34754;
$rps-white: #ffffff;
$rps-wallet-orange: #fe7439;
$rps-background: #fafafa;
$rps-wallet-muted: #f3f3f3;
$rps-wallet-grey: #595959;
$rps-wallet-light-grey: #a6a6a6;
$rps-footer-grey: #777777;

$rps-ogc-off-white: #e9e9e9;
$rps-ogc-grey: #747474;
$rps-ogc-divider-grey: #c2c2c2;
$rps-lobby-border-off-white: #c4c4c4;

$game-bar-color: #2a3163;

// overriding bootstrap variables here will change the colors throughout reactstrap
// take a look at node_modules/bootstrap/sass/_variables.scss for variable to override
$body-bg: $rps-background;
