@import './colors';

.lobby-header {
  position: relative;
  display: flex;
  justify-content: center;
}

.lobby-header-title {
  font-size: 50px;
  font-weight: bold;
}

.lobby-new-game {
  font-size: 18px;
  font-weight: bold;
  color: $rps-red;

  width: 188px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px $rps-lobby-border-off-white;
  background-color: $white;
  position: absolute;
  right: 0;
  top: 25%;
}

.lobby-ogc-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
