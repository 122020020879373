.footer-resign {
  font-weight: bold;
  font-size: 16px;
  color: $rps-footer-grey;

  width: 188px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px $rps-lobby-border-off-white;
  background-color: $rps-white;
}

.footer-challenge {
  font-weight: bold;
  font-size: 16px;
  color: $rps-footer-grey;

  margin-left: 10px;
  width: 188px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px $rps-background;
  background-color: $rps-background;
}

.navbar.footer-bar {
  background-color: $rps-background;
}

.navbar.fixed-bottom {
  z-index: 0; /* This is needed to override bootstrap, otherwise the footer shows on top of the wallet */
}

.footer-logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    opacity: 0.5;
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  }

  /* Disable grayscale on hover */
  img:hover {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: none;
  }
}
