@import '../_colors';
@import '../_fonts';

.challenge-response-container {
  padding: 28px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.challenge-response-container {
  height: 87%;
}

.challenge-response-title {
  font-size: 40px;
  font-weight: bold;
}

.challenge-response-divider {
  height: 1px;
  width: 100%;
  color: $rps-wallet-light-grey;
  border-width: 1px;
  border-style: dashed;
  margin: 30px 0;
}

.challenge-response-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: $rps-wallet-light-grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.challenge-response-user {
  font-size: 15px;
  color: $rps-white;
}

.challenge-response-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.challenge-response-icon {
  margin-top: 180px;
}

.challenge-response-button {
  margin: 0 auto;
  display: flex;
  background-color: $rps-wallet-orange;
  border-color: $rps-wallet-orange;
  color: #fff;
}
